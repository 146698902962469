export const PATH = {
  LANDING: '/',
  RESTAURANT_LIST: '/restaurant_list/',
  RESTAURANT: '/restaurant/',
  QUEUE: '/queue/',
  QUEUE_TABLE_SIZE: '/queue_table_size/',
  QUEUE_TIME_SECTION: '/queue_time_section/',
  QUEUE_SERVICE_TYPE: '/queue_service_type/',
  QUEUE_TIME: '/queue_time/',
  QUEUE_DATE: '/queue_date/',
  QUEUE_SPECIAL_REQUEST: '/queue_special_request/',
  QUEUE_PERSONAL_INFO: '/queue_personal_info/',
  QUEUE_PREVIEW: '/queue_preview/',
  PICKUP: '/pickup/',
  TICKET: '/ticket/',
  ERROR: '/error'
}