import { configureStore } from '@reduxjs/toolkit';
import systemSlice from './reducers/systemSlice';
import restaurantSlice from './reducers/restaurantSlice';
import queueSlice  from './reducers/queueSlice';
import pickupSlice  from './reducers/pickupSlice';
import ticketSlice  from './reducers/ticketSlice';

export default configureStore({
  reducer: {
    system: systemSlice,
    restaurant: restaurantSlice,
    queue: queueSlice,
    pickup: pickupSlice,
    ticket: ticketSlice,
  }
})