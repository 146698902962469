import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector, useDispatch, useStore } from 'react-redux';
import * as mqtt from "mqtt";
import * as _ from "lodash";
import routes from './routes';

import { usePrevious } from './utils/hooks';
import { MQTT_MSG_TYPE, MQTT_RESET_TIME } from './constants/constants';
import { API_URLS } from './constants/apiUrls';
import { getOne } from './utils/baseFetch';
import { setPickup, setImage, setLatestTag } from './reducers/pickupSlice';
import { Loading } from './components/Loading';

import './styles/global.scss';
import { PATH } from './constants/paths';

const App = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const loading = useSelector((state) => state.system.loading);
  // mqtt
  const mqttTopicList = useSelector((state) => state.system.mqttTopicList);
  const previousMqttTopicList = usePrevious(mqttTopicList, []);
  const [mqttClient, setMqttClient] = useState(undefined);
  const [mqttHealthCheckTimeout, setMqttHealthCheckTimeout] = useState(undefined);
  const [lastReceiveMqttTimestamp, setLastReceiveMqttTimestamp] = useState(0);

  useEffect(() => {
    resetMqttClient();
    // resetMqttTimeout();

    return function unsubscribeMqtt() {
      if (mqttHealthCheckTimeout) {
        clearTimeout(mqttHealthCheckTimeout);
      }
      if (mqttClient) {
        mqttClient.end();
      }
    };
  }, [])

  // useEffect(() => {
  //   console.log('subscribe everything', mqttTopicList);
  //   if (mqttTopicList.length > 0) {
  //     mqttClient.subscribe(mqttTopicList, function (err) {
  //       if (err) {
  //         console.log(err);
  //       }
  //     });
  //   }
  // }, [mqttClient && mqttClient.connected])

  useEffect(() => {
    // console.log('no mqttClient');
    const newTopics = _.difference(mqttTopicList, previousMqttTopicList);
    const oldTopics = _.difference(previousMqttTopicList, mqttTopicList);

    // console.log('newTopics', newTopics);
    // console.log('oldTopics', oldTopics);

    if (newTopics.length > 0) {
      // console.log('subscribe newTopics', newTopics);
      mqttClient.subscribe(newTopics, function (err) {
        if (err) {
          console.log(err);
        }
      });
    }
    if (oldTopics.length > 0) {
      console.log('unsubscribe oldTopics', oldTopics);
      mqttClient.unsubscribe(oldTopics, function (err) {
        if (err) {
          console.log(err);
        }
      });
    }
  }, [mqttClient && mqttClient.connected && mqttTopicList])

  function resetMqttClient() {
    // console.log('resetMqttClient');
    if (mqttClient) {
      mqttClient.end();
    }

    const client = mqtt.connect(`${process.env.REACT_APP_MQTT_HOST}`, {
      username: `${process.env.REACT_APP_MQTT_USERNAME}`,
      password: `${process.env.REACT_APP_MQTT_PASSWORD}`,
      keepalive: 30000
    });

    client.on('connect', function () {
      // console.log('connect');
      if (mqttTopicList.length > 0) {
        console.log('subscribe every topics', mqttTopicList);
        mqttClient.subscribe(mqttTopicList, function (err) {
          if (err) {
            console.log(err);
          }
        });
      }
    });

    client.on('reconnect', function () {
      // console.log('reconnect');
      const currentPath = window.location.pathname;
      if (currentPath.indexOf(PATH.PICKUP) !== -1) {
        const siteId = currentPath.substring(currentPath.lastIndexOf('/') + 1);
        getOne(API_URLS.PICKUP, siteId, (payload) => {
          dispatch(setPickup(payload.pickupTagList));
          dispatch(setImage(payload.image));
        }, undefined, store);
      }
    });

    client.on('message', function (topic, message) {
      console.log('topic', topic);
      console.log('mqtt message', message.toString());
      const topicContents = _.split(topic, '/');
      const messageContents = _.split(message, ',');
      if (messageContents.length > 0) {
        switch (messageContents[0]) {
          case MQTT_MSG_TYPE.PICKUP_ADD:
          case MQTT_MSG_TYPE.PICKUP_REMOVE:
            if (topicContents.length > 1) {
              getOne(API_URLS.PICKUP, topicContents[1], (payload) => {
                dispatch(setPickup(payload.pickupTagList));
                dispatch(setImage(payload.image));
                if (messageContents[1] !== undefined) {
                  dispatch(setLatestTag(messageContents[1]));
                }
              }, undefined, store);
            }
            break;
          case MQTT_MSG_TYPE.TOPIC_ALIVE:
            break;
          default:
        }
      }
      setLastReceiveMqttTimestamp(Date.now());
    });

    client.on('error', function (error) {
      console.log(error);
    })
    setMqttClient(client);
  }

  // useEffect(() => {
  //   console.log('mqttHealthCheckTimeout', mqttHealthCheckTimeout);
  //   if (mqttHealthCheckTimeout) {
  //     console.log('clear timeoutId', mqttHealthCheckTimeout);
  //     clearTimeout(mqttHealthCheckTimeout);
  //   }
  //   const timeout = setTimeout(() => {
  //     const currentTimestamp = Date.now();
  //     console.log('inside timeout', currentTimestamp);
  //     console.log('lastReceiveMqttTimestamp', lastReceiveMqttTimestamp);
  //     if (currentTimestamp - lastReceiveMqttTimestamp > MQTT_RESET_TIME) {
  //       console.log('RESET');
  //       // reset mqtt
  //       if (mqttClient) {
  //         mqttClient.end(true, () => { resetMqttClient(currentTimestamp); });
  //       } else {
  //         resetMqttClient(currentTimestamp);
  //       }
  //     }
  //   }, MQTT_RESET_TIME);

  //   console.log('timeoutId', timeout);
  //   setMqttHealthCheckTimeout(timeout);
  // }, [lastReceiveMqttTimestamp]);

  return (
    <>
      <div className="main-content">
        <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
          {routes}
        </BrowserRouter>
        <Loading openLoading={loading} />
      </div>
    </>
  )
}

export default App;