import { createSlice } from '@reduxjs/toolkit'

export const queueSlice = createSlice({
  name: 'restaurant',
  initialState: {
    queueConfig: {
      siteDetail: {
        code: 'DEFAULT',
        name: 'I don\'t know',
        imageUrl: 'https://thegulu-image-staging-1253791749.cos.ap-hongkong.myqcloud.com/restaurant/e55cacb8-2a45-4bd3-bbf8-3aced2c93f05/image_cos_url?v3?v4?v5?v0',
        showIcon: true,
        location: 'xxxxxxx xxxxxx xxxxxx',
        siteId: '123456',
      },
      termsAndConditions: "xxx tc t&c",
      status: "A",
      steps: ["DATE", "TIME_SECTION", "TICKET_TYPE", "TAGS", "PERSONAL_DATA"],
      filterResult:
        [
          { timeSectionId: "A", tableType: "A", tableTypeLabel: "服務", ticketType: "HOTPOT", ticketTypeLabel: "hot pot", timeSectionLabel: "09:00 - 10:00", minSize: 1, maxSize: 2, sizeDisplay: true, sizeSelect: true },
          { timeSectionId: "A", tableType: "B", tableTypeLabel: "服務", ticketType: "HOTPOT", ticketTypeLabel: "hot pot", timeSectionLabel: "09:00 - 10:00", minSize: 1, maxSize: 2, sizeDisplay: true, sizeSelect: true },
          { timeSectionId: "A", tableType: "C", tableTypeLabel: "服務", ticketType: "HOTPOT", ticketTypeLabel: "hot pot", timeSectionLabel: "09:00 - 10:00", minSize: 1, maxSize: 2, sizeDisplay: true, sizeSelect: true },
          { timeSectionId: "A", tableType: "D", tableTypeLabel: "服務", ticketType: "DISH", ticketTypeLabel: "dish", timeSectionLabel: "09:00 - 10:00", minSize: 1, maxSize: 2, sizeDisplay: true, sizeSelect: true },
          { timeSectionId: "A", tableType: "E", tableTypeLabel: "服務", ticketType: "DISH", ticketTypeLabel: "dish", timeSectionLabel: "09:00 - 10:00", minSize: 1, maxSize: 2, sizeDisplay: true, sizeSelect: true },
          { timeSectionId: "A", tableType: "F", tableTypeLabel: "服務", ticketType: "DISH", ticketTypeLabel: "dish", timeSectionLabel: "09:00 - 10:00", minSize: 1, maxSize: 2, sizeDisplay: true, sizeSelect: true },
          { timeSectionId: "B", tableType: "A", tableTypeLabel: "服務", ticketType: "HOTPOT", ticketTypeLabel: "hot pot", timeSectionLabel: "10:00 - 11:00", minSize: 1, maxSize: 2, sizeDisplay: true, sizeSelect: true },
          { timeSectionId: "B", tableType: "B", tableTypeLabel: "服務", ticketType: "HOTPOT", ticketTypeLabel: "hot pot", timeSectionLabel: "10:00 - 11:00", minSize: 1, maxSize: 2, sizeDisplay: true, sizeSelect: true },
          { timeSectionId: "B", tableType: "C", tableTypeLabel: "服務", ticketType: "HOTPOT", ticketTypeLabel: "hot pot", timeSectionLabel: "10:00 - 11:00", minSize: 1, maxSize: 2, sizeDisplay: true, sizeSelect: true },
          { timeSectionId: "B", tableType: "D", tableTypeLabel: "服務", ticketType: "DISH", ticketTypeLabel: "dish", timeSectionLabel: "10:00 - 11:00", minSize: 1, maxSize: 2, sizeDisplay: true, sizeSelect: true },
          { timeSectionId: "B", tableType: "E", tableTypeLabel: "服務", ticketType: "DISH", ticketTypeLabel: "dish", timeSectionLabel: "10:00 - 11:00", minSize: 1, maxSize: 2, sizeDisplay: true, sizeSelect: true },
          { timeSectionId: "B", tableType: "F", tableTypeLabel: "服務", ticketType: "DISH", ticketTypeLabel: "dish", timeSectionLabel: "10:00 - 11:00", minSize: 1, maxSize: 2, sizeDisplay: true, sizeSelect: true }
        ]
    },
    requestParams: {
      siteId: '',
      size: 0,
      tableType: '',
      date: '',
      time: '',
      timeSectionId: '',
      ticketType: '',
      tags: [],
      personalData: {
        email: '',
        countryCode: '',
        mobile: ''
      },
      nextStep: ''
    },
    queuePreview: undefined,
  },
  reducers: {
    setQueueConfig(state, action) {
      state.queueConfig = action.payload;
    },
    setQueuePreview(state, action) {
      state.queuePreview = action.payload;
    },
    setRequestParams(state, action) {
      state.requestParams = action.payload;
    }
  }
})

export const { setQueueConfig, setQueuePreview, setRequestParams } = queueSlice.actions

export default queueSlice.reducer