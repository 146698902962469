export const STRINGS = {
  TC: {
    // page titles
    pickupNumber: "取餐號碼",
    // others
    serverError: "未能完成請求",
    selectTableSize: "請選擇人數",
    selectTimeSection: "請選擇攞飛時段",
    selectServiceType: "請選擇服務",
    selectTime: "請選擇攞飛時段",
    selectDate: "請選擇攞飛日期",
    selectSpecialRequest: "請選擇特別安排",
    selectPersonalInfo: "填寫個人資料",
    selectPreview: "訂單資料",
    tableSize: "排隊人數",
    timeSection: "排隊時段",
    dateTime: "攞飛日期/時間",
    specialRequest: "特別安排",
    tnc: "條款及細則",
    email: "電郵地址*",
    phone: "電話號碼*",
    personal_info_tnc: "同意透過電郵接收優惠作推廣用。",
    invalidUrl: "沒有此頁面"
  },
  EN: {
    // page titles
    pickupNumber: "Pickup number",
    // others
    serverError: "Server error"
  }
}