export const LANG = {
  TC: "TC",
  EN: "EN"
}

export const MQTT_MSG_TYPE = {
  PICKUP_ADD: 'PICKUP_ADD',
  PICKUP_REMOVE: 'PICKUP_REMOVE',
  TOPIC_ALIVE: 'TOPIC_ALIVE'
}
export const MQTT_RESET_TIME = 10000;

export const QUEUE_STEPS = {
  CURRENT_SECTION: 'CURRENT_SECTION',
  TABLE_SIZE: 'TABLE_SIZE',
  TIME_SECTION: 'TIME_SECTION',
  TICKET_TYPE: 'TICKET_TYPE',
  TABLE_TYPE: 'TABLE_TYPE',
  DATE: 'DATE',
  TIME_SECTION: 'TIME_SECTION',
  PERSONAL_DATA: 'PERSONAL_DATA',
  PREVIEW: 'PREVIEW'
}