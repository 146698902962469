import { createSlice } from '@reduxjs/toolkit'

export const restaurantSlice = createSlice({
  name: 'restaurant',
  initialState: {
    restaurant: {}
  },
  reducers: {
    setRestaurant(state, action) {
      state.restaurant = action.payload;
    }
  }
})

export const { setRestaurant } = restaurantSlice.actions

export default restaurantSlice.reducer