import React from 'react';
import { useSelector } from 'react-redux';

import { STRINGS } from '../constants/strings';

const Error = () => {
  const lang = useSelector((state) => state.system.lang);

  return (
    <div>{STRINGS[lang].invalidUrl}</div>
  );
}

export default Error;