import { createSlice } from '@reduxjs/toolkit'

export const ticketSlice = createSlice({
  name: 'restaurant',
  initialState: {
    ticket: {},
  },
  reducers: {
    setTicket(state, action) {
      state.ticket = action.payload;
    },
  }
})

export const { setTicket } = ticketSlice.actions

export default ticketSlice.reducer