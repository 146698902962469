import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector, useStore } from 'react-redux';

import { API_URLS } from '../constants/apiUrls';
import { getOne } from '../utils/baseFetch';
import { setPickup, setImage } from '../reducers/pickupSlice';
import { subscribeMqttTopic, unsubscribeMqttTopic } from '../reducers/systemSlice';

import '../styles/pickup.scss';
import { usePrevious } from '../utils/hooks';
import { STRINGS } from '../constants/strings';
import { LANG } from '../constants/constants';

const Pickup = () => {
  const params = useParams();
  const previousParams = usePrevious(params, {});
  const dispatch = useDispatch();
  const pickup = useSelector((state) => state.pickup.pickup);
  const image = useSelector((state) => state.pickup.image);
  const lang = useSelector((state) => state.system.lang);
  const store = useStore();

  const latestTag = useSelector((state) => state.pickup.latestTag);

  useEffect(() => {
    document.title = STRINGS[lang].pickupNumber;

    return function unsubscribeMqtt() {
      const mqttTopic = 'FOODGULU_V3/' + params.restUrlId + '/#';
      dispatch(unsubscribeMqttTopic(mqttTopic));
    };
  }, []);

  useEffect(() => {
    if (previousParams.restUrlId) {
      const mqttTopic = 'FOODGULU_V3/' + previousParams.restUrlId + '/#';
      dispatch(unsubscribeMqttTopic(mqttTopic));
    }

    const mqttTopic = 'FOODGULU_V3/' + params.restUrlId + '/#';
    dispatch(subscribeMqttTopic(mqttTopic));

    getOne(API_URLS.PICKUP, params.restUrlId, (payload) => {
      dispatch(setPickup(payload.pickupTagList));
      dispatch(setImage(payload.image));
    }, undefined, store);
  }, [params.restUrlId, previousParams.restUrlId, dispatch, store]);

  return (
    <div className="pickup-wrapper">
      <div className="title-wrapper">
        <div className="rest-logo"><img className="image" src={image} alt="" /></div>
        <div className="title">
          <div className="text">
            <div className="text-zh">
              <img className="pickup-logo" src={process.env.PUBLIC_URL + "/images/pickup-logo.png"} alt="pickup" />
              <div>{STRINGS[LANG.TC].pickupNumber}</div>
            </div>
            <div>{STRINGS[LANG.EN].pickupNumber}</div>
          </div>
        </div>
        <div className="pickup-gulu-logo">
          <img className="image" src={process.env.PUBLIC_URL + "/images/gulu-logo.png"} alt="thegulu" />
          <img className="image logo-blink" src={process.env.PUBLIC_URL + "/images/gulu-logo-reverse.png"} alt="thegulu" />
        </div>
      </div>
      <div className="middle-line" />
      <div className="pickup-content">
        {
          pickup && pickup.map((pickupNumber, index) => {
            return <div key={index} className={"text" + ((pickupNumber.tag === latestTag) ? " blink" : "")}>{pickupNumber.tag}</div>
          })}
      </div>
    </div>
  );
}

export default Pickup;